import { FC, PropsWithChildren } from 'react';

import { StackProps, Stack, SxProps, Toolbar } from '@mui/material';

type MainProps = PropsWithChildren &
  StackProps & {
    sx?: SxProps;
  };

const Main: FC<MainProps> = (prop) => {
  const { children, sx, ...others } = prop;

  return (
    <Stack
      component="main"
      sx={{ px: { xs: 0, md: 3 }, pb: 0, pt: { xs: 0, md: 3 }, flex: 1 }}
      {...others}
    >
      <Toolbar sx={{ height: { xs: 63, sm: 71 } }} />
      {children}
    </Stack>
  );
};

export default Main;
