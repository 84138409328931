import paths from 'routes/routes';

const navItems = [
  {
    path: paths.root,
    title: 'Home',
  },
  {
    path: paths.debunkedClaims,
    title: 'Debunked Claims',
  },
  {
    path: paths.claimFinder,
    title: 'Similar Claims Finder',
  },
];

export default navItems;
