import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  applyFilters,
  generateAttributes,
  hasMinimumProbability,
  isNotNonHateful,
} from 'src/features/home/components/tip-tap-editor/helpers';
import {
  Article,
  DetectedRangeToHighlightTypeTransformed,
  EditorStateType,
  MatchingTextType,
  MatchingTextTypeTransformed,
  TextAnalysisResultsTransformedType,
  TextAnalysisResultsType,
} from 'src/types/main';

const HATE_SPEECH_THRESHOLD = 0.9;

const initialState: EditorStateType = {
  textAnalysisResults: null,
  enrichedRangesToHighlight: [],
  hoveredHighlightedId: '',
  isTextTouched: false,
  isLoading: false,
  articleMetadata: {
    id: '',
    claim: '',
    title: '',
    article: '',
    language: '',
    label: '',
    url: '',
    report: '',
    article_date: '',
    debunked_date: '',
    source: 'APP',
  },
};

const transformAnalysisResults = (analysisResults: TextAnalysisResultsType) => {
  const typeMapping: Record<MatchingTextType, MatchingTextTypeTransformed> = {
    CLAIM_MATCHING: 'matching_claim',
    HATE_SPEECH: 'hate_speech',
    DEBUNKED_CLAIM: 'debunked_claim',
    FALLACY_DETECTION: 'fallacy',
  };

  return analysisResults.results.map((result) => {
    const transformedType = typeMapping[result.type];

    return {
      metadata: result.metadata,
      type: transformedType,
    } as DetectedRangeToHighlightTypeTransformed;
  });
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setTextAnalysisResults: {
      reducer: (
        state,
        action: PayloadAction<TextAnalysisResultsTransformedType>
      ) => {
        state.textAnalysisResults = action.payload;
      },
      prepare: (analysisResults: TextAnalysisResultsType) => {
        const transformedResults = transformAnalysisResults(analysisResults);

        return {
          payload: {
            rawText: analysisResults.rawText,
            results: transformedResults,
          },
        };
      },
    },
    setHoveredHighlightedId(state, action: PayloadAction<string>) {
      state.hoveredHighlightedId = action.payload;
    },
    setEnrichedRangesToHighlight: {
      reducer: (
        state,
        action: PayloadAction<TextAnalysisResultsTransformedType>
      ) => {
        const filteredRanges = applyFilters(action.payload.results, [
          isNotNonHateful,
          hasMinimumProbability(HATE_SPEECH_THRESHOLD),
        ]);

        const analysisResults: TextAnalysisResultsTransformedType = {
          ...action.payload,
          results: filteredRanges,
        };

        const enrichedRanges = generateAttributes(analysisResults);
        state.enrichedRangesToHighlight = enrichedRanges;
      },
      prepare: (analysisResults: TextAnalysisResultsType) => {
        const transformedResults = transformAnalysisResults(analysisResults);
        return {
          payload: {
            rawText: analysisResults.rawText,
            results: transformedResults,
          },
        };
      },
    },
    setIsTextTouched(state, action: PayloadAction<boolean>) {
      state.isTextTouched = action.payload;
    },
    reset(state) {
      state.textAnalysisResults = null;
      state.enrichedRangesToHighlight = [];
      state.hoveredHighlightedId = '';
      state.isTextTouched = false;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setArticleMetadata(state, action: PayloadAction<Article>) {
      // If the article is loaded / scrapped the values should be populated otherwise not.
      state.articleMetadata = action.payload;
    },
    resetArticleMetadata(state) {
      state.articleMetadata = {
        id: '',
        claim: '',
        title: '',
        article: '',
        language: '',
        label: '',
        url: '',
        report: '',
        article_date: '',
        debunked_date: '',
        source: 'APP',
      };
    },
  },
});

export const {
  setTextAnalysisResults,
  setHoveredHighlightedId,
  setEnrichedRangesToHighlight,
  setIsTextTouched,
  reset,
  setIsLoading,
  setArticleMetadata,
  resetArticleMetadata,
} = editorSlice.actions;

export default editorSlice.reducer;
