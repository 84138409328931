import { Theme } from '@mui/material';

import button from './components/button';

const componentsOverrides = (theme: Theme) => {
  const components = Object.assign(button(theme));

  return components;
};

export default componentsOverrides;
