import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const EDITOR_BASE_URL = import.meta.env.VITE_EDITOR_BASE_URL;

export const editorApi = createApi({
  reducerPath: 'editorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: EDITOR_BASE_URL,
  }),
  endpoints: () => ({}),
  tagTypes: ['Editor'],
});

export const editorEndpoints = editorApi.injectEndpoints({
  endpoints: (builder) => ({
    generateReport: builder.mutation<void, string[]>({
      query: (references) => ({
        url: `/generateReport`,
        method: 'POST',
        body: { references },
      }),
    }),
  }),
});

export const { useGenerateReportMutation } = editorEndpoints;
