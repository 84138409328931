import { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import MainLayout from 'layouts/main';

import paths from 'src/routes/routes';

import authRoutes from './auth';
import errorRoutes from './error';
import mainRoutes from './main';

const HomePage = lazy(() => import('src/pages/home'));

export const routes = [
  {
    path: paths.root,
    element: (
      <MainLayout>
        <HomePage />
      </MainLayout>
    ),
  },
  ...authRoutes,
  ...mainRoutes,
  ...errorRoutes,
  { path: '*', element: <Navigate to={paths.page404} replace /> },
];

const router = createBrowserRouter(routes);

export default router;
