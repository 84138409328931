/* eslint-disable import/prefer-default-export */
import { combineReducers } from '@reduxjs/toolkit';

import { articlesApi } from './apis/articles-api';
import { editorApi } from './apis/editor-api';
import { scrappedApi } from './apis/scrapped-api';
import EditorReducer from './editor/editor-slice';
import FunctionalitiesReducer from './functionalities/functionalities-slice';

export const rootReducer = combineReducers({
  [articlesApi.reducerPath]: articlesApi.reducer,
  [editorApi.reducerPath]: editorApi.reducer,
  [scrappedApi.reducerPath]: scrappedApi.reducer,

  editor: EditorReducer,
  functionalities: FunctionalitiesReducer,
});
