import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import LoadingScreen from 'src/components/loading-screen';
import ErrorLayout from 'src/layouts/error';
import paths from 'src/routes/routes';

const Page404 = lazy(() => import('src/pages/404'));
const Page403 = lazy(() => import('src/pages/403'));

const errorRoutes = [
  {
    path: paths.root, // Root path "/"
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ErrorLayout>
          <Outlet />
        </ErrorLayout>
      </Suspense>
    ),
    children: [
      {
        path: paths.page404,
        element: <Page404 />,
      },
      {
        path: paths.page403,
        element: <Page403 />,
      },
    ],
  },
];

export default errorRoutes;
