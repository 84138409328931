import { FC, PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

import WelcomeBanner from '../components/welcome-banner';

const AuthLayout: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Stack
      component="main"
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, sm: 0 }}
      sx={{
        minHeight: '100vh',
        maxHeight: '100%',
      }}
    >
      <WelcomeBanner />
      <Stack
        sx={{
          width: 1,
          maxWidth: { sm: 350, md: 550 },
          mx: 'auto',
          px: { sm: 2, md: 8 },
          minHeight: '100vh',
          height: '100%',
          justifyContent: { sm: 'center' },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default AuthLayout;
