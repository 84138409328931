const ROOTS = {
  APP: '/',
};

const paths = {
  root: ROOTS.APP, // The root path for the home page is "/"

  page403: '/403',
  page404: '/404',

  debunkedClaims: '/debunked-claims',
  claimFinder: '/claim-finder',

  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',

  sandbox: '/sandbox',
};

export default paths;
