import { forwardRef, ElementType, Ref } from 'react';

import { Box, BoxProps } from '@mui/material';

import { Icon } from '@iconify/react';

type IconifyProps<T extends ElementType> = {
  icon: string;
  size?: number;
  component?: T;
} & BoxProps;

const Iconify = forwardRef(
  <T extends ElementType>(props: IconifyProps<T>, ref: Ref<HTMLElement>) => {
    const {
      icon,
      size = 24,
      sx = {},
      component: Component = 'div',
      ...others
    } = props;

    return (
      <Box
        component={Component}
        ref={ref}
        sx={{ width: size, height: size, ...sx }}
        {...others}
      >
        <Icon icon={icon} width={size} height={size} />
      </Box>
    );
  }
);

export default Iconify;
