import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import LoadingScreen from 'src/components/loading-screen';
import MainLayout from 'src/layouts/main';
import paths from 'src/routes/routes';

const DebunkedClaimsPage = lazy(() => import('src/pages/debunked-claims'));
const SimilarClaimsFinderPage = lazy(
  () => import('src/pages/similar-claims-finder')
);
const SandboxPage = lazy(() => import('src/pages/sandbox'));

const mainRoutes = [
  {
    path: paths.root, // Root path "/"
    element: (
      <div className="auth-guard">
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </div>
    ),
    children: [
      {
        path: paths.debunkedClaims,
        element: <DebunkedClaimsPage />,
      },
      {
        path: paths.claimFinder,
        element: <SimilarClaimsFinderPage />,
      },
      ...(import.meta.env.MODE === 'development'
        ? [
            {
              path: paths.sandbox,
              element: <SandboxPage />,
            },
          ]
        : []),
    ],
  },
];

export default mainRoutes;
