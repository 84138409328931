import {
  DetectedRangeToHighlightTypeTransformed,
  EnrichedDetectedRangeToHighlightType,
  FunctionalityKey,
  MatchingTextTypeTransformed,
  TextAnalysisResultsTransformedType,
} from 'src/types/main';

export const isNotNonHateful = (
  range: DetectedRangeToHighlightTypeTransformed
) => !(range.type === 'hate_speech' && range.metadata.label === 'Non HateFull');

export const hasMinimumProbability =
  (threshold: number) => (range: DetectedRangeToHighlightTypeTransformed) =>
    range.type !== 'hate_speech' || range.metadata.probability >= threshold;

type FilterFunction = (
  range: DetectedRangeToHighlightTypeTransformed
) => boolean;

export const applyFilters = (
  ranges: DetectedRangeToHighlightTypeTransformed[],
  filters: FilterFunction[]
) => {
  return ranges.filter((range) => filters.every((filter) => filter(range)));
};

const generatePartialIndices = (str: string, partial: string) => {
  const startIndex = str.indexOf(partial);

  if (startIndex === -1) {
    return { startIndex: -1, endIndex: -1 };
  }

  const endIndex = startIndex + partial.length - 1;
  return { startIndex, endIndex };
};

const generateId = (index: number) => `highlight-${index}`;

const generateIcon = () => 'material-symbols:circle';

const generateLabel = (type: MatchingTextTypeTransformed) => {
  const labels: Record<MatchingTextTypeTransformed, string> = {
    fallacy: 'Logical Fallacy',
    debunked_claim: 'Matching Claim',
    matching_claim: 'Debunked Claim',
    hate_speech: 'Hate Speech',
  };
  return labels[type] || '';
};

const generateColor = (type: MatchingTextTypeTransformed): string => {
  const colors: Record<MatchingTextTypeTransformed, string> = {
    fallacy: 'custom.tropicalBlue',
    debunked_claim: 'warning.light',
    matching_claim: 'custom.chinook',
    hate_speech: 'custom.melrose',
  };
  return colors[type] || '';
};

export const generateAttributes = (
  rangesToHighlight: TextAnalysisResultsTransformedType
): EnrichedDetectedRangeToHighlightType[] => {
  if (!rangesToHighlight) return [];

  const enrichedRanges = rangesToHighlight.results.map(
    (rangeToHighlight, id) => {
      let startIndicator: number;
      let endIndicator: number;

      if (rangeToHighlight.type === 'fallacy') {
        startIndicator = rangeToHighlight.metadata.startIndex;
        endIndicator = rangeToHighlight.metadata.endIndex;
      } else {
        const { startIndex, endIndex } = generatePartialIndices(
          rangesToHighlight.rawText,
          rangeToHighlight.metadata.text
        );
        startIndicator = startIndex;
        endIndicator = endIndex;
      }

      return {
        ...rangeToHighlight,
        extra: {
          startIndex: startIndicator,
          endIndex: endIndicator,
          highlightId: generateId(id),
          color: generateColor(rangeToHighlight.type),
          label: generateLabel(rangeToHighlight.type),
          icon: generateIcon(),
        },
      };
    }
  );

  const sortedRanges = enrichedRanges.toSorted(
    (a, b) => (a.extra.startIndex ?? 0) - (b.extra.startIndex ?? 0)
  );

  return sortedRanges;
};

export const mapFunctionalities = (functionalities: FunctionalityKey[]) => {
  const types: Record<FunctionalityKey, string> = {
    logical_fallacy_detection: 'FALLACY_DETECTION',
    hate_spech_detection: 'HATE_SPEECH',
    matching_claims_to_other_articles: 'DEBUNKED_CLAIM',
    matching_to_debunked_claims: 'CLAIM_MATCHING',
  };

  return functionalities.map((func) => ({ type: types[func] }));
};
