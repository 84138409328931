import { FC, PropsWithChildren } from 'react';

import { IconButton, Palette, PaletteColor, styled } from '@mui/material';

import {
  MaterialDesignContent,
  SnackbarProvider as NotistackProvider,
  SnackbarKey,
  closeSnackbar,
} from 'notistack';

import Iconify from '../iconify';

const StyledIcon = styled('span')<{
  color?: keyof Palette;
}>(({ color = 'primary', theme }) => {
  const colorValue = theme.palette[color] as PaletteColor;
  return {
    width: 44,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    backgroundColor: colorValue.main,
  };
});

export const StyledNotistack = styled(MaterialDesignContent)(({ theme }) => {
  return {
    '& #notistack-snackbar': {
      ...theme.typography.subtitle2,
      padding: 0,
      flex: 1,
    },
    '&.notistack-MuiContent': {
      padding: theme.spacing(0),
      paddingRight: theme.spacing(2),
      color: theme.palette.text.primary,
      boxShadow: theme.customShadows.z8,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  };
});

const renderAction = (snackbarId: SnackbarKey) => (
  <IconButton
    size="small"
    onClick={() => closeSnackbar(snackbarId)}
    sx={{ p: 0.5 }}
    aria-label="close snackbar"
  >
    <Iconify width={16} icon="material-symbols-light:close" />
  </IconButton>
);

type SnackbarProviderProps = PropsWithChildren;

const SnackbarProvider: FC<SnackbarProviderProps> = (props) => {
  const { children } = props;
  return (
    <NotistackProvider
      maxSnack={3}
      preventDuplicate
      autoHideDuration={3000}
      variant="success"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      iconVariant={{
        info: (
          <StyledIcon color="info">
            <Iconify icon="material-symbols-light:info" />
          </StyledIcon>
        ),
        success: (
          <StyledIcon color="success">
            <Iconify icon="material-symbols-light:check-circle-rounded" />
          </StyledIcon>
        ),
        warning: (
          <StyledIcon color="warning">
            <Iconify icon="material-symbols-light:warning-rounded" />
          </StyledIcon>
        ),
        error: (
          <StyledIcon color="error">
            <Iconify icon="material-symbols-light:dangerous" />
          </StyledIcon>
        ),
      }}
      action={renderAction}
      Components={{
        default: StyledNotistack,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
      }}
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
