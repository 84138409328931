import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

type WebSocketContextType = {
  sendMessage: (message: string) => void;
  readyState: number;
  lastMessage: WebSocketEventMap['message'] | null;
  lastJsonMessage: unknown;
  connectionStatus: string;
};

type WebSocketProviderType = PropsWithChildren & {
  url: string;
};

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider'
    );
  }
  return context;
};

export const WebSocketProvider: React.FC<WebSocketProviderType> = ({
  url,
  children,
}) => {
  const { sendMessage, lastMessage, readyState, lastJsonMessage } =
    useWebSocket(url, {
      shouldReconnect: () => true,
    });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const contextValue = useMemo(
    () => ({
      sendMessage,
      readyState,
      lastMessage,
      lastJsonMessage,
      connectionStatus,
    }),
    [sendMessage, readyState, lastMessage, lastJsonMessage, connectionStatus]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
