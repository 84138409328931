import { Box, Stack, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import CustomTypography from '../custom-typography';

const WelcomeBanner = () => {
  const mdUp = useResponsive('up', 'sm');

  const imgSrc = mdUp
    ? '/assets/sign-in-logo-large.png'
    : '/assets/sign-in-logo-small.png';

  return (
    <Stack
      sx={{ backgroundColor: 'primary.main', width: 1, flex: { sm: 1 } }}
      alignItems="center"
      justifyContent="center"
      spacing={{ xs: 2, sm: 4, md: 8 }}
      px={{ xs: 2, sm: 0 }}
      py={{ xs: 2, sm: 0 }}
    >
      <Box
        component="img"
        alt="auth"
        src={imgSrc}
        sx={{
          maxWidth: 350,
        }}
      />
      <Typography
        variant="h3"
        sx={{ color: 'secondary.main', textAlign: 'center' }}
      >
        WELCOME TO FAITH PROJECT
      </Typography>

      <CustomTypography />
    </Stack>
  );
};

export default WelcomeBanner;
