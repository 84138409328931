import {
  enUS as enUSDate,
  roRO as roRODate, // elGR as elGRDate, Uncomment if Greek lang is going to be used
} from '@mui/x-date-pickers/locales';

import {
  enUS as enUSAdapter,
  ro as roAdapter,
  el as elAdapter,
} from 'date-fns/locale';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUSDate,
    adapterLocale: enUSAdapter,
    icon: 'cif:us',
  },
  {
    label: 'Romanian',
    value: 'ro',
    systemValue: roRODate,
    adapterLocale: roAdapter,
    icon: 'cif:ro',
  },
  {
    label: 'Greek',
    value: 'el',
    // systemValue: elGRDate,  Uncomment if Greek lang is going to be used
    systemValue: enUSDate, // UI text in English
    adapterLocale: {
      ...elAdapter,
      localize: enUSAdapter.localize, // Use English for month/day names
      formatLong: elAdapter.formatLong, // Retain Greek format for dates and
    },
    icon: 'cif:gr',
  },
];

export const defaultLang = allLangs[0];
