import { alpha, PaletteMode } from '@mui/material';

const grey = {
  0: '#ffffff',
  100: '#f2f4f6',
  200: '#e6e8eb',
  300: '#d0d4da',
  400: '#b0b7c1',
  500: '#8a949f',
  600: '#6a7682',
  700: '#4b545f',
  800: '#2f3842',
  900: '#1a2128',
};

const primary = {
  light: '#6dc4ec',
  main: '#1478c3',
  dark: '#0a458c',
  contrastText: '#ffffff',
};

const secondary = {
  light: '#f7b73f',
  main: '#f5a510',
  dark: '#ab730b',
  contrastText: '#ffffff',
};

const info = {
  light: '#60ddf0',
  main: '#0097ce',
  dark: '#005894',
  contrastText: '#ffffff',
};

const success = {
  light: '#8cea76',
  main: '#24ba21',
  dark: '#108522',
  contrastText: '#000000',
};

const warning = {
  light: '#f4ce64',
  main: '#dd9504',
  dark: '#9f6002',
  contrastText: '#000000',
};

const error = {
  light: '#f09678',
  main: '#ce3123',
  dark: '#94111c',
  contrastText: '#ffffff',
};

const common = {
  black: '#000000',
  white: '#ffffff',
};

const custom = {
  yellow: '#fff000',
  chinook: '#9ae6b4',
  melrose: '#d6bcfa',
  tropicalBlue: '#bfdbfe',
};

const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  error,
  info,
  warning,
  success,
  common,
  grey,
  action,
  custom,
};

const palette = {
  ...base,
  mode: 'light' as PaletteMode,
  text: {
    primary: grey[800],
    secondary: grey[600],
    disabled: grey[500],
  },
  background: {
    paper: common.white,
    default: common.white,
    neutral: grey[200],
  },
  action: {
    ...base.action,
    active: grey[600],
  },
  button: {
    primary,
    secondary: common.black,
  },
};

export default palette;
