import { FC, PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

import Main from 'src/layouts/components/main';
import Navigation from 'src/layouts/components/navigation';

const MainLayout: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Stack
      sx={{
        minHeight: '100vh',
        maxHeight: '100%',
        maxWidth: 1536,
        mx: { xl: 'auto' },
      }}
    >
      <Navigation />

      <Main>{children}</Main>
    </Stack>
  );
};

export default MainLayout;
