import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import LoadingScreen from 'src/components/loading-screen';
import SnackbarProvider from 'src/components/snackbar-provider';
import i18n from 'src/locales/i18n';
import LocalizationProvider from 'src/locales/localization-provider';
import router from 'src/routes/sections';
import ThemeProvider from 'src/theme/theme-provider';

import { WebSocketProvider } from './contexts/web-sockets-context';
import { setupStore } from './store/store';

const App = () => {
  const WS_URL = import.meta.env.VITE_WS_URL;

  return (
    <Provider store={setupStore()}>
      <ThemeProvider>
        <SnackbarProvider>
          <I18nextProvider i18n={i18n}>
            <HelmetProvider>
              <LocalizationProvider>
                <Suspense fallback={<LoadingScreen />}>
                  <WebSocketProvider url={WS_URL}>
                    <RouterProvider
                      router={router}
                      fallbackElement={<LoadingScreen />}
                    />
                  </WebSocketProvider>
                </Suspense>
              </LocalizationProvider>
            </HelmetProvider>
          </I18nextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
