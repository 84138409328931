import { FC, PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

const ErrorLayout: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Stack
      sx={{
        minHeight: '100vh',
        maxHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Stack>
  );
};

export default ErrorLayout;
