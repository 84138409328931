import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import endpoints from 'src/store/endpoints';

import {
  Article,
  ClaimSearchFormType,
  GetArticlesParamsType,
  GetArticlesRsponseType,
  GetArticlesRsponseWithTokenType,
  NewArticle,
} from 'src/types/main';

const BASE_URL = import.meta.env.VITE_ARTICLES_BASE_URL;
const API_USERNAME = import.meta.env.VITE_API_USERNAME;
const API_PASSWORD = import.meta.env.VITE_API_PASSWORD;

const base64Credentials = btoa(`${API_USERNAME}:${API_PASSWORD}`);

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Basic ${base64Credentials}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['Articles'],
});

export const articlesEndpoints = articlesApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<
      GetArticlesRsponseWithTokenType,
      GetArticlesParamsType
    >({
      providesTags: (result) =>
        result?.docs
          ? [
              ...result.docs.map(({ id }) => ({
                type: 'Articles' as const,
                id,
              })),
              { type: 'Articles', id: 'LIST' },
            ]
          : [{ type: 'Articles', id: 'LIST' }],
      query: ({ pageSize, continuationToken, source }) => ({
        url: `/articles`,
        params: {
          pageSize,
          source,
        },
        headers: continuationToken
          ? {
              'x-continuation-token': continuationToken,
            }
          : undefined,
      }),
      transformResponse: (response: GetArticlesRsponseType, meta) => {
        const token =
          meta?.response?.headers?.get('x-continuation-token') ?? undefined;
        return { ...response, token };
      },
    }),
    getArticleById: builder.query<Article, string>({
      query: (id) => ({
        url: `${endpoints.articles}/${id}`,
      }),
    }),
    addArticle: builder.mutation<Article[], NewArticle>({
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
      query: (newArticle) => ({
        url: '/articles',
        method: 'POST',
        body: [
          {
            title: newArticle.title,
            article: newArticle.article,
            claim: '',
            language: '',
            label: '',
            url: '',
            report: newArticle?.report ?? '',
            article_date: `${new Date().toISOString().split('.')[0]}Z`,
            debunked_date: newArticle?.debunked_date ?? '',
            source: 'APP',
          },
        ],
      }),
    }),
    updateArticle: builder.mutation<Article, Article>({
      invalidatesTags: (_, __, arg) => [{ type: 'Articles', id: arg.id }],
      query: (body) => ({
        url: `${endpoints.articles}/${body.id}`,
        method: 'PUT',
        body: {
          claim: body.claim,
          title: body.title,
          article: body.article,
          language: body.language,
          label: body.label,
          url: body.url,
          report: body.report,
          article_date: body.article_date,
          debunked_date: body.debunked_date,
          source: body.source,
        },
      }),
    }),
    deleteArticle: builder.mutation<void, Partial<Article>>({
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
      query: ({ id }) => ({
        url: `${endpoints.articles}/${id}`,
        method: 'DELETE',
      }),
    }),
    searchClaim: builder.mutation<Article[], ClaimSearchFormType>({
      query: (searchProps) => ({
        url: `${endpoints.articles}/vectorsearch`,
        method: 'POST',
        body: { ...searchProps },
      }),
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetArticleByIdQuery,
  useAddArticleMutation,
  useDeleteArticleMutation,
  useUpdateArticleMutation,
  useSearchClaimMutation,
} = articlesEndpoints;
