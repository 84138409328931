import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_en from './translations/en/common.json';
import meta_en from './translations/en/meta.json';
import validation_en from './translations/en/validation.json';

const DETECTION_OPTIONS = {
  order: ['navigator'],
};

const resources = {
  en: {
    common: common_en,
    validation: validation_en,
    meta: meta_en,
  },
};

const isDevelopment = import.meta.env.MODE === 'development';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: isDevelopment,
    detection: DETECTION_OPTIONS,
    resources,
    ns: ['common', 'validation'],
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;
