import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FunctionalityKey } from 'src/types/main';

type FunctionalitiesStateType = {
  checked: FunctionalityKey[];
};

const initialState: FunctionalitiesStateType = {
  checked: [],
};

const functionalitiesSlice = createSlice({
  name: 'functionalities',
  initialState,
  reducers: {
    toggleFunctionality: (state, action: PayloadAction<FunctionalityKey>) => {
      const index = state.checked.indexOf(action.payload);
      if (index === -1) {
        state.checked.push(action.payload);
      } else {
        state.checked.splice(index, 1);
      }
    },
  },
});

export const { toggleFunctionality } = functionalitiesSlice.actions;

export default functionalitiesSlice.reducer;
