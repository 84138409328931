import { Typography, useTheme } from '@mui/material';

const CustomTypography = () => {
  const theme = useTheme();

  return (
    <Typography variant="h4" sx={{ maxWidth: 580, textAlign: 'center' }}>
      <span style={{ color: 'inherit' }}>
        <span style={{ color: theme.palette.secondary.main }}>F</span>
        ostering
      </span>{' '}
      <span style={{ color: 'inherit' }}>
        <span style={{ color: theme.palette.secondary.main }}>A</span>
        rtificial
      </span>{' '}
      <span style={{ color: 'inherit' }}>
        <span style={{ color: theme.palette.secondary.main }}>I</span>
        ntelligence
      </span>{' '}
      for{' '}
      <span style={{ color: 'inherit' }}>
        <span style={{ color: theme.palette.secondary.main }}>T</span>
        rust
      </span>{' '}
      <span style={{ color: 'inherit' }}>
        <span style={{ color: theme.palette.secondary.main }}>H</span>
        umans
      </span>{' '}
      towards the optimization of trustworthiness through large-scale pilots in
      critical domains
    </Typography>
  );
};

export default CustomTypography;
