import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import paths from 'routes/routes';

import LoadingScreen from 'src/components/loading-screen';
import AuthLayout from 'src/layouts/auth';

const SignInPage = lazy(() => import('src/pages/sign-in'));
const SignUpPage = lazy(() => import('src/pages/sign-up'));
const ForgotPasswordPage = lazy(() => import('src/pages/forgot-password'));

const authRoutes = [
  {
    path: paths.root,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </Suspense>
    ),
    children: [
      {
        path: paths.signIn,
        element: <SignInPage />,
      },
      {
        path: paths.signUp,
        element: <SignUpPage />,
      },
      {
        path: paths.forgotPassword,
        element: <ForgotPasswordPage />,
      },
    ],
  },
];

export default authRoutes;
