import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Stack,
  Button,
} from '@mui/material';

import Iconify from 'components/iconify';

import { useResponsive } from 'src/hooks/use-responsive';

import navItems from './config-navigation';

const drawerWidth = 240;

const Navigation = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const logoSrc = '/assets/faith-nav-logo.png';

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const mdDown = useResponsive('down', 'md');

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box component="img" alt="faith-logo" src={logoSrc} />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              component={NavLink}
              to={item.path}
              end
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar component="nav" elevation={mdDown ? 0 : 3}>
        <Toolbar
          sx={{
            py: { xs: 0.5, sm: 1 },
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'background.default',
          }}
        >
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Iconify icon="material-symbols:menu" />
          </IconButton>
          <Box
            component="img"
            alt="faith-logo"
            src={logoSrc}
            sx={{
              mx: { xs: 'auto', sm: 'unset' },
            }}
          />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            {navItems.map((item) => (
              <Button key={item.title} component={NavLink} to={item.path} end>
                {item.title}
              </Button>
            ))}
          </Stack>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default Navigation;
