import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ScrappedInfoType } from 'src/types/main';

const EDITOR_BASE_URL = import.meta.env.VITE_EDITOR_BASE_URL;

export const scrappedApi = createApi({
  reducerPath: 'scrappedApi',
  baseQuery: fetchBaseQuery({
    baseUrl: EDITOR_BASE_URL,
  }),
  endpoints: () => ({}),
  tagTypes: ['ScrappedInfo'],
});

export const editorEndpoints = scrappedApi.injectEndpoints({
  endpoints: (builder) => ({
    scrapeUrl: builder.mutation<Omit<ScrappedInfoType, 'url'>, string>({
      query: (url) => ({
        url: `/scrape`,
        method: 'POST',
        body: { insertedUrl: url },
      }),
    }),
  }),
});

export const { useScrapeUrlMutation } = editorEndpoints;
