import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { articlesApi } from './apis/articles-api';
import { editorApi } from './apis/editor-api';
import { scrappedApi } from './apis/scrapped-api';
import { rootReducer } from './root-reducer';

export const setupStore = (preloadedState?: Partial<RootState>) => {
  const str = configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        articlesApi.middleware,
        editorApi.middleware,
        scrappedApi.middleware
      ),

    preloadedState,
  });

  setupListeners(str.dispatch);

  return str;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
