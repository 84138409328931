import { FC, PropsWithChildren, useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

import useLocales from 'src/locales/use-locales';

import breakpoints from './breakpoints';
import componentsOverrides from './overrides';
import palette from './palette';
import { customShadows, shadows } from './shadows';
import typography from './typography';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { currentLang } = useLocales();

  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
      breakpoints,
      shape: { borderRadius: 4 },
      shadows,
      customShadows,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverrides(theme);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
